const details = {
    firstName: "Ramanilayam",
    lastName: "Tourist Home",
    fullName: "Ramanilayam Tourist Home",
    email: "ramanilayamamz@gmail.com",
    phone: "+91 9446582750",
    phoneNoCode: "9446582750",
    whatzappUrl: "https://wa.me/c/919446582750",
    coverPhotoURL: '/assets/images/avatars/avatar_default.jpg',
    fbpage: 'https://www.facebook.com/RamanilayamAmbalapuzha/',
    instapage: 'https://www.instagram.com/ramanilayamambalapuzha/',
    locurl: 'https://www.google.com/maps/place/Ramanilayam+Tourist+Home/@9.3835203,76.3674407,17z/data=!3m1!4b1!4m9!3m8!1s0x3b089d4509748bd3:0xb1bccfc68f447fb2!5m2!4m1!1i2!8m2!3d9.3835203!4d76.3674407!16s%2Fg%2F11j4xnlzhv?entry=ttu',
    direction: 'https://www.google.com/maps/dir//Ramanilayam+Tourist+Home,+West+Gate+Ambalapuzha+Temple,+Ambalapuzha,+Kerala+688561,+India/@9.3835203,76.3674407,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3b089d4509748bd3:0xb1bccfc68f447fb2!2m2!1d76.3674407!2d9.3835203!3e0?entry=ttu',
    mapPage: 'https://maps.app.goo.gl/hFjipqYYHbP9YXfh9'
};

export default details;
