import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';
import * as CONSTANTS from './constants'
import DirectionsIcon from '@material-ui/icons/Directions';
import details from './details';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginBottom: 0,
    display: 'flex',
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.warning.main,
    padding: theme.spacing(8, 3),
  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: '100%',
  },
  imagesWrapper: {
    position: 'relative',
  },
  imageDots: {
    position: 'absolute',
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: '100%',
    background: 'url(/static/onepirate/productCTAImageDots.png)',
  },
  image: {
    position: 'absolute',
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: 600,
  },
});

function ProductCTA(props) {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container className={classes.root} component="section">
      <Grid container>
        <Grid item xs={12} md={6} className={classes.cardWrapper}>
          <div className={classes.card}>
            <form onSubmit={handleSubmit} className={classes.cardContent}>
              <Typography variant="h4" component="h2" gutterBottom align="center">
                Facilities
              </Typography>
              <Typography variant="h5" align="center">
              * 24-hr reception * Free Wifi * Cable TV * Power laundry steam pressed linens only * Parking 
              * Hot water * Power backup * courteous staff * Clean, neat and cozy bedrooms 
              * Economical * Family Friendly * Nearest to Ambalapuzha temple 
              * Near to many sightseeing spots in Alappuzuha * Near Ambalapuzha railway station 
              * Near bus station and national highway *
              </Typography>
              {/* <a href={details.locurl} target="_blank"> */}
                <Button
                  // color="secondary"
                  variant="contained"
                  size="small"
                  className={classes.button}
                  component="a"
                  startIcon={<DirectionsIcon />}
                  // backgroundColor="#25D366"
                  onClick={() => {
                    window.open(details.locurl, '_blank', 'noopener,noreferrer');
                  }}
                >
                  Get direction
      </Button>
              {/* </a> */}
              {/* <span className="get-direction">
                <div className="as-link material-icons" href={details.direction}>
                  <u>Get direction<DirectionsIcon /></u>
                  <span className="fas fa-location-arrow"></span>
                </div>
              </span> */}
              {/* <TextField noBorder className={classes.textField} placeholder="Your email" />
              <Button type="submit" color="primary" variant="contained" className={classes.button}>
                Keep me updated
              </Button> */}
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imagesWrapper}>
          <Hidden smDown>
            <div className={classes.imageDots} />
            <img
              src={CONSTANTS.PHOTOS_FRONT_VIEW_2}
              alt="call to action"
              className={classes.image}
            />
          </Hidden>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        onClose={handleClose}
        message="We will send you our best offers, once a week."
      />
    </Container>
  );
}

ProductCTA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCTA);
