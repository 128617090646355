import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import { Link } from "react-router-dom";
import WifiIcon from '@material-ui/icons/Wifi'
import AirlineSeatFlatIcon from '@material-ui/icons/AirlineSeatFlat';
import { Box } from '@material-ui/core';
import details from './details';
import CallIcon from '@material-ui/icons/Call';

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
  more: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Rooms
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                {/* <div className={classes.number}>1.</div> */}
                <Box component="span" m={1}>
                  <AirlineSeatFlatIcon></AirlineSeatFlatIcon>
                  <AirlineSeatFlatIcon></AirlineSeatFlatIcon>
                  <AirlineSeatFlatIcon></AirlineSeatFlatIcon>
                </Box>

                {/* <img
                  src="/productHowItWorks1.svg"
                  // src= {<WifiIcon></WifiIcon>}//
                  alt="suitcase"
                  className={classes.image}
                /> */}
                <Typography variant="h5" align="center" display="block">
                  <span>{'Deluxe Room'}</span><br></br>
                  <span>{'3 Adults'}</span><br></br>
                  <span>{'AC ₹ 1450'}</span><br></br>
                  <span>{'Non AC ₹ 990'}</span>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                {/* <div className={classes.number}>2.</div> */}
                <Box component="span" m={1}>
                  <AirlineSeatFlatIcon></AirlineSeatFlatIcon>
                  <AirlineSeatFlatIcon></AirlineSeatFlatIcon>
                </Box>
                {/* <img
                  src="/productHowItWorks2.svg"
                  alt="graph"
                  className={classes.image}
                /> */}
                <Typography variant="h5" align="center">
                  <span>{'Double Room'}</span><br></br>
                  <span>{'2 Adults'}</span><br></br>
                  <span>{'AC ₹ 1350'}</span><br></br>
                  <span>{'Non AC ₹ 880'}</span>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                {/* <div className={classes.number}>3.</div> */}
                <Box component="span" m={1}>
                  <AirlineSeatFlatIcon></AirlineSeatFlatIcon>
                </Box>
                {/* <img
                  src="/productHowItWorks3.svg"
                  alt="clock"
                  className={classes.image}
                /> */}
                <Typography variant="h5" align="center">
                  <span>{'Single Room'}</span><br></br>
                  <span>{'1 Adult'}</span><br></br>
                  <span>{'AC ₹ 1050'}</span><br></br>
                  <span>{'Non AC ₹ 550'}</span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Typography variant="body2" color="inherit" marked="center" className={classes.more}>
          * All rates are inclusive of GST
        </Typography>
        {/* <a href={"tel:" + details.phone}> */}
          <Button
            color="secondary"
            variant="contained"
            size="large"
            className={classes.button}
            component="a"
            startIcon={<CallIcon />}
            onClick={() => {
              window.open("tel:"+ details.phone, '_blank', 'noopener,noreferrer');
            }}
          >
            Book Now
      </Button>
        {/* </a> */}
        {/* <Link to={"/signup"}>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            className={classes.button}
            component="a"
          >
            Get started
        </Button>
        </Link> */}
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
