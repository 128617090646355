export const PHOTOS_COMPLIMENTARY_1 = 'http://drive.google.com/uc?export=view&id=1g1lqsrKS750wnCqFqwW3OlpCQObj1QSp'
export const PHOTOS_DELUXE_1 = 'http://drive.google.com/uc?export=view&id=1q6vIqPt--Y9p8byaGonn5DrktWxlTR3p'
export const PHOTOS_DELUXE_2 = 'http://drive.google.com/uc?export=view&id=1jx8Ca7hprbOcgDI5ysPqFOX0ozWacilL'
export const PHOTOS_DOUBLE_1 = 'http://drive.google.com/uc?export=view&id=1OZimVWL8dtDdiI4GW9Bhp3wbp7xYsXy8'
export const PHOTOS_DOUBLE_2 = 'http://drive.google.com/uc?export=view&id=17BTn3U8UZSWgx2bQaQyk1jbAsQhJVLqJ'
export const PHOTOS_ENTRANCE_1 = 'http://drive.google.com/uc?export=view&id=1WQvLSSoEom-WXbobAfcteFIVk9g_OWHK'
export const PHOTOS_FRONT_VIEW_1 = 'http://drive.google.com/uc?export=view&id=1xd5EnMoNak6n9u4qpCm8osNf5wMZbaeb'
export const PHOTOS_FRONT_VIEW_2 = 'http://drive.google.com/uc?export=view&id=1oMU4e8zUNYpXha6Td6Au66RR5c3RBDZT'
export const PHOTOS_FRONT_VIEW_3 = 'http://drive.google.com/uc?export=view&id=15Ud3jc8_4SVmm6i0o9k-Y3jre6dJNQ3-'
export const PHOTOS_HALL_1 = 'http://drive.google.com/uc?export=view&id=13iglcod4D9_UPx7CDbnYXchO5_jJ0Ft6'
export const PHOTOS_HALL_2 = 'http://drive.google.com/uc?export=view&id=155OeO_uROf-Dn1Dt5fCBd_H7rvqaGby5'
export const PHOTOS_PASSAGE_1 = 'http://drive.google.com/uc?export=view&id=1ghV-SGwWUMjaTuQyuNE7SjAq9YV3jFQ4'
export const PHOTOS_PASSAGE_2 = 'http://drive.google.com/uc?export=view&id=1YKfYDK3SXQ630VD1BS-ZbxTMjXby9jH3'
export const PHOTOS_PASSAGE_3 = 'http://drive.google.com/uc?export=view&id=1SjkTBSg93wd-b5AW1ssKL0CmVwVXpoVz'
export const PHOTOS_RECEPTION_1 = 'http://drive.google.com/uc?export=view&id=1jHoGEvp2MDnT9hKGI-jHOQiR9lXDj3Fx'
export const PHOTOS_RECEPTION_2 = 'http://drive.google.com/uc?export=view&id=1_z__EE6oMH8mn2EtMVRlevoyDcBHWtRv'
export const PHOTOS_SIDE_VIEW_1 = 'http://drive.google.com/uc?export=view&id=1uTk8msVqOgl5DBM1boMfVH7uyh2Dk464'
export const PHOTOS_SINGLE_1 = 'http://drive.google.com/uc?export=view&id=1FkRpOmVQHkfP6RRgKk9tgsJIk3oHwWAw'
export const PHOTOS_SINGLE_2 = 'http://drive.google.com/uc?export=view&id=1JBgQnNIQBiKeHOKoXRiYEsslBMRzdHLv'
export const PHOTOS_TOP_VIEW_1 = 'http://drive.google.com/uc?export=view&id=1YpfXFCVvgsejrsr8bb6HiEh7qY8RoLyX'
export const PHOTOS_WATER_HEATER = 'http://drive.google.com/uc?export=view&id=1l4DjbnSlaHOaSsF5q2fWdRmDFlZHDyH3'
